<template>
  <div>
    <v-list-item v-for="champ in champs">
      <v-list-item-content>
        <v-list-item-title>{{ champ }}: {{ c[champ] }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DefautContrat",
  props: ['c'],
  computed: {
    champs() {
      const to_remove = ['contrat_ptr_id', 'numero', 'parcelles', 'personnes', 'type']
      return Object.keys(this.c).filter(element => !to_remove.includes(element) && element.slice(-3) !== '_id')
    },
  },
}
</script>

<style scoped>

</style>