<template>
  <div>
    Ici bientôt : le formulaire que vous attendez
    <v-container>
      <v-row>
        <v-col cols="6">

        </v-col>
        <v-col cols="6">

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">

        </v-col>
        <v-col cols="6">

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {format_functions} from "@/mixins/UtilityMixin";
import {mapActions, mapState} from "vuex";

export default {
  name: "DefautContrat",
  mixins: [format_functions],
  props: ['refs'],
  data() {
    return {
      defaultItem: {},
    }
  },
  created() {
    if (Object.keys(this.editedItem).length === 0) {
      this.init_ei(this.defaultItem)
    }
  },
  methods: {
    ...mapActions('edition', [
      'init_ei',
      'update_ei'
    ]),
  },
  computed: {
    ...mapState({
      editedItem: state => state.edition.editedItem
    }),
  },
}
</script>

<style scoped>

</style>